import { TRegularBoxType } from "@ecp-pageTypes";
import {
  ISrpTransformResponseV2,
  ISrpWithSearchTransformResponseV2,
  TSrpProductType,
} from "@ecp-redux/dto/searchResults.types";
import {
  IElementVisibility,
  ProductHoverEffects,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useState } from "react";
import { useTheme } from "styled-components";
import {
  IBoxProductSliderMessages,
  IBoxProductSliderSettings,
} from "../../../../../boxes/BoxProductSlider/BoxProductSlider.types";
import {
  IBoxSearchResultsContentSettings,
  IBoxSearchResultsMessages,
  IBoxSearchResultsSettings,
} from "../../../../../boxes/BoxSearchResults/BoxSearchResults.types";
import ConditionalWrapper from "../../../../../global/components/ConditionalWrapper/ConditionalWrapper";
import ImageWrapper from "../../../../../global/components/ImageWrapper/ImageWrapper";
import {
  DEFAULT_IMAGE_WIDTH,
  useObserveElementDimensions,
} from "../../../../../global/components/ImageWrapper/ImageWrapper.methods";
import { SelectedGoToOption } from "../../../../../settingsPatterns/contentPatterns.types";
import { CommunicationBetweenBoxesProvider } from "../../../../../structure/Contexts/CommunicationBetweenBoxes";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import ClipboardIcon from "../../../Clipboard/ClipboardIcon";
import { TVariant } from "../ProductVariants/ProductVariants.types";
import AttributesInColumn from "./AttributesInColumn";
import AttributesInRow from "./AttributesInRow";
import BadgesList from "./BadgesList/BadgesList";
import { StyledBadgesList } from "./BadgesList/BadgesList.styled";
import {
  StyledLeftPictureMode,
  StyledProductModuleWrapper,
} from "./ProductModule.styled";
import {
  IProductModuleElementsStyle,
  IProductModulePhoto,
  IProductModuleSettings,
} from "./ProductModule.types";
import VariantsList from "./VariantsList/VariantsList";

interface IProductModuleProps {
  product: ISrpTransformResponseV2;
  moduleSettings: IProductModuleSettings;
  productPhoto: IProductModulePhoto;
  badge: IElementVisibility;
  favorites: IElementVisibility;
  buttonText: string;
  ratingText: string;
  productSettings:
    | Partial<IBoxSearchResultsContentSettings["product"]>
    | Partial<IProductModuleElementsStyle>;
  orderInModule: (keyof IProductModuleElementsStyle | string)[];
  boxId: number;
  boxType: TRegularBoxType;
  variantSettings?: TVariant;
  attributesLineDetection: ISrpWithSearchTransformResponseV2["attributesLineDetection"];
  leftPictureMode?: {
    photoSize: number;
    pictureAlignment: AlignmentVerticalOption;
    attributesAlignment: AlignmentVerticalOption;
    spaceBetweenPhotoAndAttributes: number;
    badgesShown: boolean;
  };
  setIsNextImageLoaded?: (loaded: boolean) => void;
}

const ProductModule: React.FC<IProductModuleProps> = ({
  product,
  moduleSettings,
  productPhoto,
  badge,
  favorites,
  buttonText,
  productSettings,
  orderInModule,
  ratingText,
  boxId,
  boxType,
  variantSettings,
  attributesLineDetection,
  leftPictureMode,
  setIsNextImageLoaded,
}) => {
  const {
    advanceSettings: { settings: globalSettings },
    globalObjects,
  } = useTheme() as IThemeState;
  const [imgWrapper, imageWidth] = useObserveElementDimensions();

  const [, setIsProductImageLoaded] = useState(false);

  const handleImageLoad = (loaded: boolean) => {
    setIsProductImageLoaded(loaded);

    setIsNextImageLoaded?.(loaded);
  };

  const { settings } = useMessagesSettingsContext<
    IBoxSearchResultsMessages | IBoxProductSliderMessages,
    IBoxSearchResultsSettings | IBoxProductSliderSettings
  >();

  const attributesRowsTable = [
    settings.attribute_row_1,
    settings.attribute_row_2,
    settings.attribute_row_3,
    settings.attribute_row_4,
    settings.attribute_row_5,
    settings.attribute_row_6,
    settings.attribute_row_7,
    settings.attribute_row_8,
    settings.attribute_row_9,
    settings.attribute_row_10,
  ];

  const placeholder = globalSettings.dynamicBoxImagePlug;

  return (
    <CommunicationBetweenBoxesProvider>
      <StyledProductModuleWrapper
        className="product-module"
        data-testid="product-module"
        $imageHover={productPhoto.hover}
        imageHeight={productPhoto.height}
        ref={imgWrapper}
        module={moduleSettings}
        $productUrl={product.url}
        $spaceBottom={moduleSettings.spaceBetweenPhotoAndContent}
        leftPictureMode={leftPictureMode}
        productPhotoHeight={productPhoto.height}
        productWithoutCoverPhoto={
          product.coverPhoto === null || product.coverPhoto === ""
        }
      >
        <ConditionalWrapper
          wrapper={(children) => (
            <StyledLeftPictureMode
              gap={leftPictureMode?.spaceBetweenPhotoAndAttributes}
              attributesAlignment={leftPictureMode?.attributesAlignment}
              photoSize={leftPictureMode?.photoSize}
            >
              {children}
            </StyledLeftPictureMode>
          )}
          condition={leftPictureMode !== undefined}
        >
          <>
            <div className="product-module__link">
              <div className="product-module__link__container">
                <div className="product-module__link__container__badge-container">
                  {badge.show && (
                    <StyledBadgesList className="product-module__link__container__badge-container__badge">
                      <BadgesList product={product} />
                    </StyledBadgesList>
                  )}
                  {leftPictureMode && favorites.show && (
                    <ClipboardIcon
                      isOnWishList={
                        product.conceptId === null
                          ? (product.wishlist?.base ?? false)
                          : (product.wishlist?.concept ?? false)
                      }
                      productData={{
                        sku: product.conceptId
                          ? product.conceptId
                          : product.sku,
                        type: product.conceptId
                          ? TSrpProductType.CONCEPT
                          : TSrpProductType.PRODUCT,
                      }}
                    />
                  )}
                </div>
                <div className="imageContainer product-module__link__container__image-container">
                  <ImageWrapper
                    link={{
                      selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                      goToPageSetting: {
                        openInNewTab: false,
                        link: product.url,
                      },
                    }}
                    imageUrl={product.coverPhoto || placeholder}
                    seoDescription={product.name}
                    imageFit={false}
                    imageAlignment={{
                      vertical: AlignmentVerticalOption.CENTER,
                      horizontal: AlignmentHorizontalOption.CENTER,
                    }}
                    width={DEFAULT_IMAGE_WIDTH}
                    height={productPhoto.height}
                    setIsNextImageLoaded={handleImageLoad}
                  />

                  {productPhoto.hover === ProductHoverEffects.SECOND &&
                    product.hoverPhoto && (
                      <div className="hoverPhoto">
                        <ImageWrapper
                          link={{
                            selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                            goToPageSetting: {
                              openInNewTab: false,
                              link: product.url,
                            },
                          }}
                          imageUrl={product.hoverPhoto}
                          seoDescription={product.name}
                          imageFit={false}
                          imageAlignment={{
                            vertical: AlignmentVerticalOption.CENTER,
                            horizontal: AlignmentHorizontalOption.CENTER,
                          }}
                          width={DEFAULT_IMAGE_WIDTH}
                          height={productPhoto.height}
                        />
                      </div>
                    )}
                </div>
                {!leftPictureMode && (
                  <div className="product-module__link__container__variants-container">
                    {variantSettings && (
                      <VariantsList
                        key={
                          JSON.stringify(variantSettings) +
                          JSON.stringify(globalObjects) +
                          imageWidth
                        }
                        productSku={product.sku}
                        productUrl={product.url}
                        variantSettings={variantSettings}
                        variants={product.variants}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            {!leftPictureMode && favorites.show && (
              <ClipboardIcon
                isOnWishList={
                  product.conceptId === null
                    ? (product.wishlist?.base ?? false)
                    : (product.wishlist?.concept ?? false)
                }
                productData={{
                  sku: product.conceptId ? product.conceptId : product.sku,
                  type: product.conceptId
                    ? TSrpProductType.CONCEPT
                    : TSrpProductType.PRODUCT,
                }}
              />
            )}

            <ConditionalWrapper
              condition={leftPictureMode !== undefined}
              wrapper={(children) => (
                <div className="product-module__left-picture-mode-attributes-container">
                  {children}
                </div>
              )}
            >
              <>
                {settings.attribiute_in_row === "false" && (
                  <AttributesInColumn
                    product={product}
                    productSettings={productSettings}
                    orderInModule={orderInModule}
                    boxId={boxId}
                    boxType={boxType}
                    buttonText={buttonText}
                    ratingText={ratingText}
                    moduleSettings={moduleSettings}
                    attributesLineDetection={attributesLineDetection}
                    variantSettings={variantSettings}
                  />
                )}
                {settings.attribiute_in_row === "true" && (
                  <AttributesInRow
                    attributesRows={attributesRowsTable}
                    productSettings={productSettings}
                    product={product}
                    orderInModule={orderInModule}
                    boxId={boxId}
                    boxType={boxType}
                    buttonText={buttonText}
                    ratingText={ratingText}
                    moduleSettings={moduleSettings}
                    attributesLineDetection={attributesLineDetection}
                    variantSettings={variantSettings}
                  />
                )}
                {leftPictureMode && (
                  <div className="product-module__left-picture-mode-variants-container">
                    {variantSettings && (
                      <VariantsList
                        key={
                          JSON.stringify(variantSettings) +
                          JSON.stringify(globalObjects) +
                          imageWidth
                        }
                        productSku={product.sku}
                        variantSettings={variantSettings}
                        variants={product.variants}
                        productUrl={product.url}
                      />
                    )}
                  </div>
                )}
              </>
            </ConditionalWrapper>
          </>
        </ConditionalWrapper>
      </StyledProductModuleWrapper>
    </CommunicationBetweenBoxesProvider>
  );
};

export default ProductModule;
